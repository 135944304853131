import * as React from 'react'
import { LocalizationProvider } from '@workfront/localize-react'
import { css } from 'emotion'

const clientFactory = () => ({
  namespace: 'redrock',
  enableTerminology: false,
})

export const FVGPageContainer = ({ children }) => {
  return (
    <LocalizationProvider clientFactory={clientFactory}>
      <div className={fvgPageContainerStyles}>{children}</div>
    </LocalizationProvider>
  )
}

export const fvgPageContainerStyles = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`
