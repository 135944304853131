import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import { LocalizationProvider } from '@workfront/localize-react'

import { FiltersList } from './components/FiltersList'
import { GroupingsList } from './components/GroupingsList'
import { ViewsList } from './components/ViewsList'

const clientFactory = () => ({
  namespace: 'redrock',
  enableTerminology: false,
})

export const App = () => {
  return (
    <LocalizationProvider clientFactory={clientFactory}>
      <Routes>
        <Route path="/setup/filters" element={<FiltersList />} />
        <Route path="/setup/groupings" element={<GroupingsList />} />
        <Route path="/setup/views" element={<ViewsList />} />
      </Routes>
    </LocalizationProvider>
  )
}
