import React from 'react'
import { WrappedListContainer } from '@wf-mfe/lists'
import { SetupHeader } from '@setup/components'
import { Localization } from '@workfront/localize-react'
import TemplatesExtraLargeHeroIcon from 'phoenix-icons/dist/TemplatesExtraLargeHeroIcon.js'
import { FVGPageContainer } from './FVGPageContainer'
import { UIGroupBy } from 'workfront-objcodes'
import { deleteDialogFactory } from './deleteDialogFactory'
import { listContainerStyles } from '../styles/styles'

export const GroupingsList = (props) => {
  return (
    <FVGPageContainer>
      <SetupHeader
        icon={<TemplatesExtraLargeHeroIcon />}
        contextSensitiveHelpPageID="csh-list-uigroupbys"
        title={
          <Localization messageKey="groupby.plural" fallback="Groupings" />
        }
        section={<Localization messageKey="interface" fallback="Interface" />}
      />
      <div className={listContainerStyles}>
        <WrappedListContainer
          dialogs={{
            'delete-uigb-dialog': deleteDialogFactory(UIGroupBy),
          }}
          tileName="list-uigroupbys"
          {...props}
        />
      </div>
    </FVGPageContainer>
  )
}
