import React from 'react'
import { DeleteConfirmationDialog } from '@wf-mfe/dialogs'

export const deleteDialogFactory = (objCode) => {
  return ({ data, reloadParentTile, onClose }) => (
    <DeleteConfirmationDialog
      {...data.data}
      forceDelete
      isOpen
      objCode={objCode}
      objIDs={data.data.selectedIDs}
      onClose={onClose}
      onSuccess={reloadParentTile}
      showThrownErrorToast
    />
  )
}
