import React from 'react'
import { WrappedListContainer } from '@wf-mfe/lists'
import { SetupHeader } from '@setup/components'
import { Localization } from '@workfront/localize-react'
import TemplatesExtraLargeHeroIcon from 'phoenix-icons/dist/TemplatesExtraLargeHeroIcon.js'
import { FVGPageContainer } from './FVGPageContainer'
import { UIView } from 'workfront-objcodes'
import { deleteDialogFactory } from './deleteDialogFactory'
import { listContainerStyles } from '../styles/styles'

export const ViewsList = (props) => {
  return (
    <FVGPageContainer>
      <SetupHeader
        icon={<TemplatesExtraLargeHeroIcon />}
        contextSensitiveHelpPageID="csh-list-uiviews"
        title={<Localization messageKey="customview.plural" fallback="Views" />}
        section={<Localization messageKey="interface" fallback="Interface" />}
      />
      <div className={listContainerStyles}>
        <WrappedListContainer
          dialogs={{
            'delete-uivw-dialog': deleteDialogFactory(UIView),
          }}
          tileName="list-uiviews"
          {...props}
        />
      </div>
    </FVGPageContainer>
  )
}
