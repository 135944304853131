import { App } from './App'
import { FiltersList } from './components/FiltersList'
import { GroupingsList } from './components/GroupingsList'
import { ViewsList } from './components/ViewsList'

export const FiltersListComponent = FiltersList
export const ViewsListComponent = ViewsList
export const GroupingsListComponent = GroupingsList

export default App
